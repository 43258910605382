.footer {
  margin-top: auto;
  /* height: 100px; */
  /* width: 100px; */
  margin-top: 30px;
  background-color: #234f66;
  padding-bottom: 30px;
}

.footer-header {
  font-size: larger;
  font-weight: 500;
  color: white;
}

.footer-links {
  color: white;
  text-decoration: underline;
  cursor: pointer;
}

.inline-block {
  display: inline-block;
  padding: 7px;
}

.href-blank-fb {
  text-decoration: none;
  color: #4267b2;
}
.href-blank-insta {
  text-decoration: none;
  color: #c13584;
}
.href-blank-youtube {
  text-decoration: none;
  color: #ff0000;
}
.href-blank-wp {
  text-decoration: none;
  color: #25d366;
}
/* Col {
  align-items: flex-end;
} */

.col {
  text-align: center;
}
@media screen and (max-width: 600px) {
  .footer-header {
    font-size: medium;
    font-weight: 200;
  }
}
