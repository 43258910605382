.img {
  width: 230px;
  height: 200px;
  background-color: #edeae1;
  padding: 5px;

  border-radius: 20px;
}

.img-inner {
  width: 220px;
  height: 190px;
  object-fit: cover;
  border-radius: 20px;
}

@media screen and (max-width: 600px) {
  .img {
    width: 150px;
    height: 135px;
    background-color: #edeae1;
    padding: 5px;
    /* margin-right: 10px; */
    border-radius: 20px;
  }

  .img-inner {
    width: 140px;
    height: 125px;
    object-fit: cover;
    border-radius: 20px;
  }
}
