.card-body-custom {
  /* background-color: red; */
  margin-top: 100px;
}

.primary {
  background-color: #234f66;
  margin-top: 10px;
}

.card-outline {
  width: 350px;
  display: inline-block;
}

.toast-center {
  text-align: center;
  margin-top: 10px;
}
.card {
  width: 350px !important;

  box-shadow: -1px 1px 25px -2px rgba(35, 79, 102, 0.75);
  -webkit-box-shadow: -1px 1px 25px -2px rgba(35, 79, 102, 0.75);
  -moz-box-shadow: -1px 1px 25px -2px rgba(35, 79, 102, 0.75);

  border-radius: 20px;
}

.card:hover {
  cursor: pointer;
}

.card-img {
  width: 350px !important;
  border-radius: 20px 20px 0 0 !important;
}
.col-cus {
  text-align: center;
}
.card-head-text {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 250px;
  /* background-color: red; */
  font-size: x-large;
  color: #234f66;
  font-weight: bold;
}
.interior-card {
  background-color: #d9cfc5;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  flex-direction: row;
}
.map-card {
  background-color: #fee3ce;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  flex-direction: row;
}

.map-card > div > img {
  width: 50px;
  height: 50px;
}
.interior-card > div > img {
  width: 50px;
  height: 50px;
}

.residential-card {
  background-color: #b7d2d6;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  flex-direction: row;
}
.residential-card > div > img {
  width: 50px;
  height: 50px;
}

.residential-card-1 {
  background-color: #b7d2d6;
  /* border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px; */
  display: flex;
  flex-direction: row;
  border-bottom: solid #234f66 1px;
}
.residential-card-1 > div > img {
  width: 50px;
  height: 50px;
}

.card-selection {
  margin-left: 20px;
  margin-top: 10px;
  color: #234f66;
  font-weight: 500;
}

@media screen and (min-width: 992px) {
  .card-img {
    width: 300px !important;
    height: 300px !important;
  }
  .card-img-1 {
    width: 300px !important;
    height: 380px !important;
  }

  /* .col3 {
    margin-top: 20px;
  } */

  .col-cus {
    width: 300px;
    height: 400px;
  }
  .card {
    width: 300px !important;
  }
}

@media screen and (max-width: 600px) {
  .col-cus {
    margin-left: 20px !important;
    margin-right: 20px !important;
    /* background-color: blue; */
  }
  .col2 {
    margin-left: 20px !important;
    margin-right: 20px !important;
    /* background-color: blue; */
    margin-top: 30px;
    margin-bottom: 50px;
  }
  .col3 {
    margin-top: -20px;
  }
}
