/* .row {
  width: 400px;
  height: 300px;
} */

.float-left {
  margin-left: 10px;
  font-size: 15px;
  display: inline-block;
}

.inline {
  display: inline-block;
}

h1 {
  color: #234f66;
}
